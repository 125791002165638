"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultCustomer = exports.customerPhone = exports.customerEmail = void 0;
const customerEmail = exports.customerEmail = {
  email: '',
  emailDesc: ''
};
const customerPhone = type => {
  return {
    phoneNumber: '',
    phoneDesc: type
  };
};
exports.customerPhone = customerPhone;
const defaultCustomer = exports.defaultCustomer = {
  type: '',
  isMainApplicant: true,
  driverLicence: {
    licenceNumber: '',
    expiryDate: null
  },
  name: {
    genderCode: 1,
    givenName: '',
    middleName: '',
    familyName: '',
    businessName: '',
    isMainDriver: true
  },
  address: {
    addressLine: [],
    city: '',
    stateOrProvince: '',
    country: '',
    postalCode: '',
    sameAsApplicant: true
  },
  contact: {
    phoneNumber: [customerPhone('Day Phone'), customerPhone('Evening Phone'), customerPhone('Cell Phone')],
    email: [customerEmail, customerEmail]
  }
};