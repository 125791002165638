"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchVehicles = exports.putVehicle = exports.postVehicle = exports.patchVehicle = exports.getVehicle = exports.deleteVehicle = exports.decodeVin = exports.XMS_API_URL = void 0;
var _request = require("../../tools/request");
const XMS_API_URL = exports.XMS_API_URL = process.env.REACT_APP_XMS_API_URL;

/**
 * Create a vehicle
 * @param {Object} body
 * @returns {Promise}
 */
const postVehicle = async _ref => {
  let {
    body
  } = _ref;
  return await (0, _request.post)(`${XMS_API_URL}/delta/vehicles`, body);
};

/**
 * Vehicle by vehicleId
 * @param {Number} vehicleId
 * @returns {Promise}
 */
exports.postVehicle = postVehicle;
const getVehicle = async _ref2 => {
  let {
    vehicleId
  } = _ref2;
  return await (0, _request.get)(`${XMS_API_URL}/delta/vehicles/${vehicleId}`);
};

/**
 * Save a vehicle by vehicleId
 * @param {Number} vehicleId
 * @param {Object} body
 * @returns {Promise}
 */
exports.getVehicle = getVehicle;
const putVehicle = async _ref3 => {
  let {
    vehicleId,
    body
  } = _ref3;
  return await (0, _request.put)(`${XMS_API_URL}/delta/vehicles/${vehicleId}`, body);
};

/**
 * Delete a vehicle by vehicleId
 * @param {Number} vehicleId
 * @returns {Promise}
 */
exports.putVehicle = putVehicle;
const deleteVehicle = async _ref4 => {
  let {
    vehicleId
  } = _ref4;
  return await (0, _request.deleteHttp)(`${XMS_API_URL}/delta/vehicles/${vehicleId}`);
};

/**
 * Vehicles Id
 * @param {Number} vehicleId
 * @param {String} action
 * @returns {Promise}
 */
exports.deleteVehicle = deleteVehicle;
const patchVehicle = async _ref5 => {
  let {
    vehicleId,
    action
  } = _ref5;
  return await (0, _request.patch)(`${XMS_API_URL}/delta/vehicles/${vehicleId}?action=${action}`);
};

/**
 * Vehicle Vin Decode
 * @param {String} vin
 * @returns {Promise}
 */
exports.patchVehicle = patchVehicle;
const decodeVin = async vin => {
  return await (0, _request.get)(`${XMS_API_URL}/delta/vehicles/vins/${vin}`);
};

/**
 * List of vehicles
 * @param {Object} body
 * @returns {Promise}
 */
exports.decodeVin = decodeVin;
const searchVehicles = async _ref6 => {
  let {
    bodyRequest
  } = _ref6;
  return await (0, _request.post)(`${XMS_API_URL}/delta/vehicles/search`, bodyRequest);
};
exports.searchVehicles = searchVehicles;