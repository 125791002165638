"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  ActivityService: true,
  AuthorizationService: true,
  ContactService: true,
  DealerInfoService: true,
  DealService: true,
  FilterService: true,
  LeadInfoService: true,
  LeadService: true,
  EmailTemplateService: true,
  QuoteService: true,
  ShowroomService: true,
  StatusService: true,
  TradeInService: true,
  VehicleService: true,
  VehicleESService: true,
  VehicleStaticDataService: true
};
exports.VehicleStaticDataService = exports.VehicleService = exports.VehicleESService = exports.TradeInService = exports.StatusService = exports.ShowroomService = exports.QuoteService = exports.LeadService = exports.LeadInfoService = exports.FilterService = exports.EmailTemplateService = exports.DealerInfoService = exports.DealService = exports.ContactService = exports.AuthorizationService = exports.ActivityService = void 0;
var _ActivityService = _interopRequireWildcard(require("./api/ActivityService"));
exports.ActivityService = _ActivityService;
var _AuthorizationService = _interopRequireWildcard(require("./api/AuthorizationService"));
exports.AuthorizationService = _AuthorizationService;
var _ContactService = _interopRequireWildcard(require("./api/ContactService"));
exports.ContactService = _ContactService;
var _DealerInfoService = _interopRequireWildcard(require("./api/DealerInfoService"));
exports.DealerInfoService = _DealerInfoService;
var _DealService = _interopRequireWildcard(require("./api/DealService"));
exports.DealService = _DealService;
var _FilterService = _interopRequireWildcard(require("./api/FilterService"));
exports.FilterService = _FilterService;
var _LeadInfoService = _interopRequireWildcard(require("./api/LeadInfoService"));
exports.LeadInfoService = _LeadInfoService;
var _LeadService = _interopRequireWildcard(require("./api/LeadService"));
exports.LeadService = _LeadService;
var _EmailTemplateService = _interopRequireWildcard(require("./api/EmailTemplateService"));
exports.EmailTemplateService = _EmailTemplateService;
var _QuoteService = _interopRequireWildcard(require("./api/QuoteService"));
exports.QuoteService = _QuoteService;
var _ShowroomService = _interopRequireWildcard(require("./api/ShowroomService"));
exports.ShowroomService = _ShowroomService;
var _StatusService = _interopRequireWildcard(require("./api/StatusService"));
exports.StatusService = _StatusService;
var _TradeInService = _interopRequireWildcard(require("./api/TradeInService"));
exports.TradeInService = _TradeInService;
var _VehicleService = _interopRequireWildcard(require("./api/VehicleService"));
exports.VehicleService = _VehicleService;
var _VehicleESService = _interopRequireWildcard(require("./api/VehicleESService"));
exports.VehicleESService = _VehicleESService;
var _VehicleStaticDataService = _interopRequireWildcard(require("./api/VehicleStaticDataService"));
exports.VehicleStaticDataService = _VehicleStaticDataService;
var _queries = require("./queries");
Object.keys(_queries).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _queries[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _queries[key];
    }
  });
});
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }