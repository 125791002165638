"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _requestMatches = require("../../selectors/requestMatches");
/**
 * @param {String} transactionToken
 * @returns {Array} - [perfectMatches, similarMatches]
 */
function* _default(transactionToken) {
  const queryParams = yield (0, _effects.select)(_requestMatches.getQueryParams);
  const paymentOptions = yield (0, _effects.select)(_requestMatches.getPaymentOptions);
  queryParams.showroomModule = 'desking';
  try {
    return yield (0, _effects.call)(_commonsApi.VehicleESService.getAllMatchVehicles, transactionToken, queryParams, paymentOptions);
  } catch (error) {
    // TODO STDESK-70 Temporary don't display the error message when this endpoint stuck
    // yield put(errorActions.handleError(error, 'getMatches'));

    return null;
  }
}