"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _commonsUtils = require("@sm360/commons-utils");
var _useMobileSendEvent = _interopRequireDefault(require("./useMobileSendEvent"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Allows mobile app to display an overlay screen (if there is any for the active route)
 * Will automatically disallow it when the component is unmounted
 * @param {String} allowed
 */
const useMobileAllowOverlay = allowed => {
  const sendMessageToNative = (0, _useMobileSendEvent.default)();
  const handleDisplayOverlay = (0, _react.useCallback)(v => {
    sendMessageToNative(_commonsUtils.MOBILE_CRM_REQUEST_TYPES.ALLOW_OVERLAY, !!v);
  }, [sendMessageToNative]);
  (0, _react.useEffect)(() => {
    handleDisplayOverlay(allowed);
    return () => handleDisplayOverlay(false);
  }, [allowed, handleDisplayOverlay]);
};
var _default = exports.default = useMobileAllowOverlay;