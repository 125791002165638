"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("../actions");
var _selectors = require("../selectors");
var _filters = require("../selectors/filters");
var _paymentOptions = require("../selectors/paymentOptions");
var _requestInventoryVehicles = require("../selectors/requestInventoryVehicles");
const {
  getNewVehicles,
  getUsedVehicles
} = _commonsApi.VehicleESService;
function* _default() {
  const {
    location,
    organizationId,
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const priceIncreaseRollCount = yield (0, _effects.select)(_deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount);
  const queryParams = {
    includeMetadata: true,
    location,
    organizationId,
    organizationUnitId,
    showroomModule: 'desking'
  };
  const isNew = yield (0, _effects.select)(_filters.getIsCategoryNew);
  const {
    pageNumber,
    pageSize
  } = yield (0, _effects.select)(_selectors.getPagination);
  const sortList = yield (0, _effects.select)(_selectors.getSort);
  const paymentOptions = yield (0, _effects.select)(_paymentOptions.getPaymentOptions);
  const vehicle = yield (0, _effects.select)(_requestInventoryVehicles.getVehicleFilters);
  try {
    const callee = isNew ? getNewVehicles : getUsedVehicles;
    const {
      totalElements,
      inventoryVehicles,
      paymentOptionsMetadata
    } = yield (0, _effects.call)(callee, queryParams, {
      pagination: {
        pageNumber,
        pageSize
      },
      paymentOptionRequest: {
        ...paymentOptions,
        priceIncreaseRollCount
      },
      sortList: [sortList],
      vehicle
    });
    yield (0, _effects.put)(_actions.Creators.loadVehiclesSuccess({
      totalElements,
      vehicles: inventoryVehicles
    }));
    yield (0, _effects.put)(_actions.Creators.setPaymentOptionsMetadata(paymentOptionsMetadata || []));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getVehicles'));
  }
}