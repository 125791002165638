"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
/**
 */
function _default(_ref) {
  let {
    uploadedFormsIds
  } = _ref;
  return function* () {
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    try {
      const updatedDealJacket = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.deleteDealJacketForms, quoteId, uploadedFormsIds, queryParams);
      if (updatedDealJacket) {
        yield (0, _effects.put)(_actions.Creators.updateTabFormsSuccess(updatedDealJacket));
      }
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'deleteDealJacketForms'));
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
      return null;
    }
  }();
}