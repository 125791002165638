"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
/**
 * Returns a function allowing to send events to mobile app
 */
const useMobileSendEvent = () => (0, _react.useCallback)((type, data) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({
      origin: 'CrmDesktopApp',
      type,
      data
    }));
  }
}, []);
var _default = exports.default = useMobileSendEvent;