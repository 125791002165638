"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortBy = exports.set = exports.sanitizeSearch = exports.replaceLinksByHtmlTags = exports.pick = exports.objectMap = exports.nullOrString = exports.nullOrNumber = exports.mapDriverLicenseToContact = exports.isPlainObject = exports.isEqual = exports.isEmpty = exports.groupBy = exports.getUserLabel = exports.getUrlParam = exports.formatPhoneNumber = exports.flattenDeep = exports.compareUserLabel = exports.compareObjectByKey = exports.cleanPhoneNumber = exports.arrayUnion = void 0;
var _constants = require("./constants");
const GLOBAL_PHONE_REGEX = new RegExp(_constants.PHONE_REGEX, 'g');
const GLOBAL_URL_REGEX = new RegExp(_constants.URL_REGEX, 'g');

// Invoke a function `fn` on each value in simple object
// @returns {Object} a new object with mutated values
const objectMap = (object, fn) => {
  if (typeof fn !== 'function') return object;
  return typeof object === 'object' ? Object.entries(object).reduce((acc, _ref) => {
    let [key, value] = _ref;
    return {
      ...acc,
      [key]: fn(value)
    };
  }, {}) : {};
};

// Compare values of two objects for a given `compareKey`
// @returns {Integer} a positive or negative value representing the comparison value
exports.objectMap = objectMap;
const compareObjectByKey = (a, b, compareKey, descending) => {
  const lesserIndex = descending ? 1 : -1;
  const greaterIndex = descending ? -1 : 1;
  return a?.[compareKey] > b?.[compareKey] ? greaterIndex : lesserIndex;
};

// Unify two arrays using a `compareKey` as a join reference
// @returns {Array} a new unified array
exports.compareObjectByKey = compareObjectByKey;
const arrayUnion = function () {
  let array1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let array2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let compareKey = arguments.length > 2 ? arguments[2] : undefined;
  const mergedArrays = [...(Array.isArray(array1) ? array1 : []), ...(Array.isArray(array2) ? array2 : [])];
  if (!compareKey) return mergedArrays;
  return mergedArrays.filter((set => o => set.has(o?.[compareKey]) ? false : set.add(o[compareKey]))(new Set()));
};

// Cast a value to String if the value is truthy or return null
exports.arrayUnion = arrayUnion;
const nullOrString = value => {
  if (!value && value !== 0) return null;else return String(value);
};

// Cast a value to Number if the value is truthy or return null
exports.nullOrString = nullOrString;
const nullOrNumber = value => {
  if (!value && value !== 0) return null;else return Number(value);
};

// Sanitize a search string (converting any phone number to a standard form, remove unsupported characters)
exports.nullOrNumber = nullOrNumber;
const sanitizeSearch = searchText => {
  if (!searchText) return '';
  let parsedText = searchText;
  if (typeof searchText === 'number') parsedText = String(searchText);
  return parsedText.replaceAll(GLOBAL_PHONE_REGEX, '$2$3$4').replaceAll('-', ' ')?.trim()?.toLowerCase();
};

// Replace all links in a string by its corresponding HTML <a> tag
exports.sanitizeSearch = sanitizeSearch;
const replaceLinksByHtmlTags = (str, externalLinks) => {
  if (typeof str !== 'string') return str;
  return str.replaceAll(GLOBAL_URL_REGEX, `<a href="$1" ${externalLinks ? 'target="_blank" rel="noopener noreferrer"' : ''}>$1</a>`);
};

// --- Phone numbers related ---

// Remove all unnecessary characters/formatting from a phone number
exports.replaceLinksByHtmlTags = replaceLinksByHtmlTags;
const cleanPhoneNumber = number => {
  const phoneNumber = number ? String(number) : '';
  let cleanedNumber = phoneNumber.trim().replace('+1', '').replace(/\D+/g, '').replaceAll(' ', '');
  if (cleanedNumber.startsWith('1') && cleanedNumber.length > 1) {
    cleanedNumber = cleanedNumber.substring(1, cleanedNumber.length);
  }
  return cleanedNumber;
};

// Format a phone number using the (XXX) XXX-XXXX pattern
exports.cleanPhoneNumber = cleanPhoneNumber;
const formatPhoneNumber = phoneNumberString => {
  const cleaned = cleanPhoneNumber(phoneNumberString);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return cleaned;
};

// --- Users related ---

// Compute a formatted label for a given user
// @return {String} full name of a user - anonymous if no names
exports.formatPhoneNumber = formatPhoneNumber;
const getUserLabel = (user, t) => {
  const firstname = user?.firstname?.trim() || user?.firstName?.trim() || '';
  const lastname = user?.lastname?.trim() || user?.lastName?.trim() || '';
  const businessName = user?.businessname?.trim() || user?.businessName?.trim() || '';
  const explicitBusiness = user?.business === true;
  const isInactive = user?.active === false;
  const fullname = (explicitBusiness ? businessName : `${firstname} ${lastname}`.trim() || businessName)?.trim() || `${t('commons.selectMultiple.label.anonymous')}${user?.id ? ` (${user.id})` : ''}`;
  return `${isInactive ? `(${t('commons.INACTIVE')}) ` : ''}${fullname}`;
};

// Compare two users' label (anonymous user being in last position)
// @returns {Integer} a positive or negative value representing the comparison value
exports.getUserLabel = getUserLabel;
const compareUserLabel = (userA, userB, t) => {
  const anonymous = t('commons.selectMultiple.label.anonymous');
  const inactive = t('commons.INACTIVE');
  const isUserAInactive = userA?.label?.includes(inactive);
  const isUserBInactive = userB?.label?.includes(inactive);
  const isUserAAnonymous = userA?.label?.includes(anonymous);
  const isUserBAnonymous = userB?.label?.includes(anonymous);
  if (isUserAInactive && !isUserBInactive) return 2;
  if (!isUserAInactive && isUserBInactive) return -2;
  if (isUserAAnonymous && !isUserBAnonymous) return 1;
  if (!isUserAAnonymous && isUserBAnonymous) return -1;
  return userA.label.localeCompare(userB.label);
};

// Checks if value is a plain object, that is, an object created by the Object constructor or one with a [[Prototype]] of null
exports.compareUserLabel = compareUserLabel;
const isPlainObject = value => {
  if (typeof value !== 'object' || value === null) return false;
  if (Object.prototype.toString.call(value) !== '[object Object]') return false;
  const proto = Object.getPrototypeOf(value);
  if (proto === null) return true;
  const Ctor = Object.prototype.hasOwnProperty.call(proto, 'constructor') && proto.constructor;
  return typeof Ctor === 'function' && Ctor instanceof Ctor && Function.prototype.call(Ctor) === Function.prototype.call(value);
};

// Checks if value is an empty object, collection
// This Native version does not support evaluating a Set or a Map
exports.isPlainObject = isPlainObject;
const isEmpty = value => [Object, Array].includes((value || {}).constructor) && !Object.entries(value || {}).length;

// Performs a deep comparison between two values to determine if they are equivalent
exports.isEmpty = isEmpty;
const isEqual = (x, y) => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? ok(x).length === ok(y).length && ok(x).every(key => isEqual(x[key], y[key])) : x === y;
};

// Recursively flattens an array
exports.isEqual = isEqual;
const flattenDeep = arr => Array.isArray(arr) ? arr.reduce((a, b) => a.concat(flattenDeep(b)), []) : [arr];

// Creates an object composed of the picked object properties.
exports.flattenDeep = flattenDeep;
const pick = (object, keys) => {
  return keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
};

// Creates an object composed of the picked object properties.
exports.pick = pick;
const sortBy = (array, key) => {
  if (!Array.isArray(array)) return undefined;
  const sortedArray = [...array];
  return sortedArray.sort((a, b) => {
    const valueA = key ? a[key] : a;
    const valueB = key ? b[key] : b;
    return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
  });
};

// Creates an object composed of keys generated from the results of running each element of collection thru 'fnBy'.
exports.sortBy = sortBy;
const groupBy = (xs, fnBy) => {
  const valueProcessor = x => typeof fnBy === 'function' ? fnBy(x) : x[fnBy];
  return xs.reduce((rv, x) => {
    (rv[valueProcessor(x)] = rv[valueProcessor(x)] || []).push(x);
    return rv;
  }, {});
};

// Sets the value at path of object. If a portion of path doesn't exist, it's created.
exports.groupBy = groupBy;
const set = (obj, path, value) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
};

// Get a specific parameter by its key from the current URL
exports.set = set;
const getUrlParam = key => new URLSearchParams(window.location.search).get(key);

// Convert a driver license contact (built by mobile app) to a crm contact structure
exports.getUrlParam = getUrlParam;
const mapDriverLicenseToContact = driverLicenseData => ({
  firstname: nullOrString(driverLicenseData?.firstName),
  lastname: nullOrString(driverLicenseData?.familyName),
  sexId: nullOrNumber(driverLicenseData?.sex),
  dateOfBirth: nullOrNumber(driverLicenseData?.dateOfBirth),
  address: nullOrString(driverLicenseData?.address),
  city: nullOrString(driverLicenseData?.city),
  postalCode: nullOrString(driverLicenseData?.zip),
  province: nullOrString(driverLicenseData?.state),
  country: nullOrString(driverLicenseData?.country),
  driverLicense: nullOrString(driverLicenseData?.idNumber)
});
exports.mapDriverLicenseToContact = mapDriverLicenseToContact;