"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _actions = require("../../actions");
var _customer = require("../../../constants/customer");
function getDefaultCustomer(contact) {
  const currentDefaultCustomer = structuredClone(_customer.defaultCustomer);
  if ((0, _commonsUtils.isEmpty)(contact)) {
    return currentDefaultCustomer;
  }
  const {
    lastname,
    middlename,
    firstname,
    businessName,
    email1,
    email2,
    mobilePhone,
    homePhone,
    workPhone,
    address,
    address2,
    city,
    country,
    isCompany,
    postalCode,
    province,
    sexId
  } = contact;
  currentDefaultCustomer.type = isCompany ? 'business' : 'individual';
  if (address) {
    currentDefaultCustomer.address.addressLine[0] = address;
  }
  if (address2) {
    currentDefaultCustomer.address.addressLine[1] = address2;
  }
  if (city) {
    currentDefaultCustomer.address.city = city;
  }
  if (country) {
    currentDefaultCustomer.address.country = country;
  }
  if (postalCode) {
    currentDefaultCustomer.address.postalCode = postalCode;
  }
  if (province) {
    currentDefaultCustomer.address.stateOrProvince = province;
  }
  if (email1) {
    currentDefaultCustomer.contact.email[0].email = email1;
  }
  if (email2) {
    currentDefaultCustomer.contact.email[1].email = email2;
  }
  if (workPhone) {
    currentDefaultCustomer.contact.phoneNumber[0].phoneNumber = workPhone;
  }
  if (homePhone) {
    currentDefaultCustomer.contact.phoneNumber[1].phoneNumber = homePhone;
  }
  if (mobilePhone) {
    currentDefaultCustomer.contact.phoneNumber[2].phoneNumber = mobilePhone;
  }
  if (businessName) {
    currentDefaultCustomer.name.businessName = businessName;
  }
  if (firstname) {
    currentDefaultCustomer.name.givenName = firstname;
  }
  if (middlename) {
    currentDefaultCustomer.name.middleName = middlename;
  }
  if (lastname) {
    currentDefaultCustomer.name.familyName = lastname;
  }
  if (sexId) {
    currentDefaultCustomer.name.genderCode = sexId;
  }
  return currentDefaultCustomer;
}

/**
 */
function* getCustomer() {
  const {
    organizationId,
    organizationUnitId,
    location,
    lang
  } = yield (0, _effects.select)(_selectors.getConfig);
  const leadId = yield (0, _effects.select)(_selectors.getLeadId);
  const queryParams = {
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    location,
    lang,
    mainApplicant: true
  };
  try {
    let response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getCustomer, leadId, queryParams);
    if ((0, _commonsUtils.isEmpty)(response)) {
      const contact = yield (0, _effects.select)(_selectors.getContact);
      response = getDefaultCustomer(contact);
    }
    yield (0, _effects.put)(_actions.Creators.setCustomer(response));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getCustomer'));
  }
}
var _default = exports.default = getCustomer;