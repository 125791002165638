"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
/**
 * Indicates if we're in the mobile app or not
 * @returns {Boolean} isMobile
 */
const useIsMobile = () => {
  const isMobile = (0, _react.useMemo)(() => {
    if (!window) return false;
    return !!window.isInReactNative;
  }, []);
  return isMobile;
};
var _default = exports.default = useIsMobile;