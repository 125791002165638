"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TAB_FORMS = void 0;
const TAB_FORMS = exports.TAB_FORMS = {
  FUNDING_STATUS: {
    RECEIVED: {
      value: 'RECEIVED',
      color: 'secondary'
    },
    BOOKED: {
      value: 'BOOKED',
      color: 'success'
    },
    HELD: {
      value: 'HELD',
      color: 'warning'
    },
    REJECTED: {
      value: 'REJECTED',
      color: 'alert'
    }
  }
};