"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isSmsActivityOutbound = exports.isSmsActivityInbound = exports.isSmsActivity = exports.hasSmsActivityFailed = exports.groupSmsActivitiesByThread = exports.getThreadUnreadMessageCount = exports.getThreadIdFromNumbers = exports.getFilteredActivities = exports.getDealerNumberFromThreadId = exports.getDealerNumberFromActivity = exports.getCustomerNumberFromThreadId = exports.getCustomerNumberFromActivity = exports.getActivityUserFullName = exports.getActivityStatusLabel = exports.getActivityStatusColorClassName = exports.getActivityContactFullName = exports.ACTIVITY_STATUSES = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const INBOUND_SMS_TYPES = ['SMS_RECEIVED'];
const OUTBOUND_SMS_TYPES = ['SMS', 'SMS_NOT_DELIVERED'];
const FAILED_SMS_TYPES = ['SMS_NOT_DELIVERED'];
const ACTIVITY_COLOR_CLASSES = {
  PAST: 'crm-activity-bg-color--past',
  TODAY: 'crm-activity-bg-color--today',
  FUTURE: 'crm-activity-bg-color--future',
  COMPLETED: 'crm-activity-bg-color--completed',
  CANCELED: 'crm-activity-bg-color--canceled'
};
const ACTIVITY_STATUSES = exports.ACTIVITY_STATUSES = {
  ACTIVE: 1,
  CANCELED: 3,
  COMPLETED: 4,
  NA: 5,
  DELETED: 6,
  INVALID: 7,
  DUPLICATE: 8
};
const getActivityStatusLabel = (activityStatusId, t) => {
  switch (activityStatusId) {
    case ACTIVITY_STATUSES.ACTIVE:
      return t('actions.shared.activityStatuses.OPTIONS.ACTIVE');
    case ACTIVITY_STATUSES.CANCELED:
      return t('actions.shared.activityStatuses.OPTIONS.CANCELED');
    case ACTIVITY_STATUSES.COMPLETED:
      return t('actions.shared.activityStatuses.OPTIONS.COMPLETED');
    case ACTIVITY_STATUSES.NA:
      return t('actions.shared.activityStatuses.OPTIONS.NA');
    case ACTIVITY_STATUSES.DELETED:
      return t('actions.shared.activityStatuses.OPTIONS.DELETED');
    case ACTIVITY_STATUSES.INVALID:
      return t('actions.shared.activityStatuses.OPTIONS.INVALID');
    case ACTIVITY_STATUSES.DUPLICATE:
      return t('actions.shared.activityStatuses.OPTIONS.DUPLICATE');
    default:
      return null;
  }
};
exports.getActivityStatusLabel = getActivityStatusLabel;
const getActivityStatusColorClassName = (statusId, startDate, endDate, dueDate) => {
  const parsedStartDate = typeof startDate !== 'object' ? new Date(startDate) : startDate;
  const parsedEndDate = typeof endDate !== 'object' ? new Date(endDate) : endDate;
  const parsedDueDate = typeof dueDate !== 'object' ? new Date(dueDate) : dueDate;
  const startToday = new Date();
  startToday.setHours(0);
  startToday.setMinutes(0);
  startToday.setSeconds(0);
  startToday.setMilliseconds(0);
  const endToday = new Date();
  endToday.setHours(23);
  endToday.setMinutes(59);
  endToday.setSeconds(59);
  endToday.setMilliseconds(999);
  let color;
  switch (statusId) {
    case ACTIVITY_STATUSES.ACTIVE:
      if (parsedStartDate >= startToday && (parsedEndDate <= endToday || parsedDueDate <= endToday)) {
        color = ACTIVITY_COLOR_CLASSES.TODAY;
      } else if (parsedStartDate < startToday) {
        color = ACTIVITY_COLOR_CLASSES.PAST;
      } else if (parsedStartDate > startToday) {
        color = ACTIVITY_COLOR_CLASSES.FUTURE;
      }
      break;
    case ACTIVITY_STATUSES.CANCELED:
    case ACTIVITY_STATUSES.DELETED:
    case ACTIVITY_STATUSES.INVALID:
    case ACTIVITY_STATUSES.DUPLICATE:
      color = ACTIVITY_COLOR_CLASSES.CANCELED;
      break;
    case ACTIVITY_STATUSES.COMPLETED:
      color = ACTIVITY_COLOR_CLASSES.COMPLETED;
      break;
    default:
      color = ACTIVITY_COLOR_CLASSES.TODAY;
      break;
  }
  return color;
};
exports.getActivityStatusColorClassName = getActivityStatusColorClassName;
const getCustomerNumberFromActivity = activity => {
  const {
    phoneFrom,
    phoneTo,
    phone,
    type
  } = activity || {};
  switch (type) {
    case 'SMS':
      return (0, _commonsUtils.cleanPhoneNumber)(phone);
    case 'SMS_NOT_DELIVERED':
      return (0, _commonsUtils.cleanPhoneNumber)(phoneTo);
    case 'SMS_RECEIVED':
      return (0, _commonsUtils.cleanPhoneNumber)(phoneFrom);
    default:
      return null;
  }
};
exports.getCustomerNumberFromActivity = getCustomerNumberFromActivity;
const getDealerNumberFromActivity = activity => {
  const {
    phoneFrom,
    phoneTo,
    type
  } = activity || {};
  switch (type) {
    case 'SMS':
    case 'SMS_NOT_DELIVERED':
      return (0, _commonsUtils.cleanPhoneNumber)(phoneFrom);
    case 'SMS_RECEIVED':
      return (0, _commonsUtils.cleanPhoneNumber)(phoneTo);
    default:
      return null;
  }
};
exports.getDealerNumberFromActivity = getDealerNumberFromActivity;
const getActivityUserFullName = (activity, t) => (0, _commonsUtils.getUserLabel)({
  firstname: activity?.userFirstName,
  lastname: activity?.userLastName
}, t);
exports.getActivityUserFullName = getActivityUserFullName;
const getActivityContactFullName = (activity, t) => (0, _commonsUtils.getUserLabel)({
  firstname: activity?.contactFirstName,
  lastname: activity?.contactLastName
}, t);
exports.getActivityContactFullName = getActivityContactFullName;
const isSmsActivityInbound = activity => INBOUND_SMS_TYPES.includes(activity?.type);
exports.isSmsActivityInbound = isSmsActivityInbound;
const isSmsActivityOutbound = activity => OUTBOUND_SMS_TYPES.includes(activity?.type);
exports.isSmsActivityOutbound = isSmsActivityOutbound;
const hasSmsActivityFailed = activity => FAILED_SMS_TYPES.includes(activity?.type);
exports.hasSmsActivityFailed = hasSmsActivityFailed;
const isSmsActivity = activity => isSmsActivityInbound(activity) || isSmsActivityOutbound(activity);

// SMS thread related
exports.isSmsActivity = isSmsActivity;
const getThreadIdFromNumbers = (from, to) => from && to ? `${from}/${to}` : null;
exports.getThreadIdFromNumbers = getThreadIdFromNumbers;
const getDealerNumberFromThreadId = threadId => threadId.split('/')[0];
exports.getDealerNumberFromThreadId = getDealerNumberFromThreadId;
const getCustomerNumberFromThreadId = threadId => threadId.split('/')[1];
exports.getCustomerNumberFromThreadId = getCustomerNumberFromThreadId;
const getThreadUnreadMessageCount = thread => thread.activities ? thread.activities.reduce((acc, a) => acc + (a?.unread ? 1 : 0), 0) : 0;
exports.getThreadUnreadMessageCount = getThreadUnreadMessageCount;
const getFilteredActivities = function () {
  let activities = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let leadId = arguments.length > 1 ? arguments[1] : undefined;
  let leadOnly = arguments.length > 2 ? arguments[2] : undefined;
  return activities.filter(a => leadId && leadOnly ? a?.activity?.leadId === leadId : true).sort((a, b) => b.activity.dateCreated - a.activity.dateCreated);
};
exports.getFilteredActivities = getFilteredActivities;
const groupSmsActivitiesByThread = (smsActivities, allSmsNotifications) => {
  return smsActivities.reduce((acc, activity) => {
    if (isSmsActivity(activity.activity)) {
      const hasUnreadNotification = !!allSmsNotifications.find(s => s.activityId === activity.id);
      const dealerNumber = getDealerNumberFromActivity(activity.activity);
      const customerNumber = getCustomerNumberFromActivity(activity.activity);
      const activityToAdd = {
        ...activity,
        unread: hasUnreadNotification
      };
      const existingThreadIndex = acc.findIndex(thread => thread.dealerNumber === dealerNumber && thread.customerNumber === customerNumber);
      if (existingThreadIndex >= 0) {
        const existingThread = acc[existingThreadIndex];
        const updatedThread = {
          ...existingThread,
          activities: [...existingThread.activities, activityToAdd]
        };
        const updatedAcc = [...acc];
        updatedAcc.splice(existingThreadIndex, 1, updatedThread);
        return updatedAcc;
      } else {
        return [...acc, {
          id: getThreadIdFromNumbers(dealerNumber, customerNumber),
          dealerNumber,
          customerNumber,
          activities: [activityToAdd]
        }];
      }
    } else return acc;
  }, []).map(thread => {
    const sortedActivities = thread.activities.sort((a, b) => b.activity.dateCreated - a.activity.dateCreated);
    return {
      ...thread,
      activities: sortedActivities
    };
  });
};
exports.groupSmsActivitiesByThread = groupSmsActivitiesByThread;