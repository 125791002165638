"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _tabForms = require("../../selectors/tabForms");
var _reloadTabForms = _interopRequireDefault(require("./reloadTabForms"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Detect if the recently selected tab need to be reloaded with new params
 */
function* _default() {
  const {
    isUpdated
  } = yield (0, _effects.select)(_tabForms.getTabForms);
  if (!isUpdated) {
    yield (0, _effects.call)(_reloadTabForms.default);
  }
}