"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DELIVERY_DATE = exports.DECISION_STATUS = void 0;
const DECISION_STATUS = exports.DECISION_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  CONDITIONED: 'Conditioned',
  CANCELLED: 'Cancelled',
  WITHDRAWN: 'Withdrawn',
  ERROR: 'Error',
  PASSED: 'Passed',
  FAILED: 'Failed'
};
const DELIVERY_DATE = exports.DELIVERY_DATE = 'deliveryDate';