"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTabFormsDocuments = exports.getTabForms = exports.getIsPrinting = exports.getDealJacketForms = exports.getDealJacket = void 0;
var _commonsUtils = require("@sm360/commons-utils");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getTabForms = state => {
  const {
    tabForms
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabForms) ? tabForms : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabForms = getTabForms;
const getDealJacket = state => {
  const {
    tabForms
  } = state;
  return tabForms?.dealJacket ?? {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDealJacket = getDealJacket;
const getDealJacketForms = state => {
  const {
    tabForms
  } = state;
  return tabForms?.dealJacket?.forms ?? [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDealJacketForms = getDealJacketForms;
const getTabFormsDocuments = state => {
  const {
    tabForms
  } = state;
  return tabForms?.documents ?? [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabFormsDocuments = getTabFormsDocuments;
const getIsPrinting = state => {
  const {
    tabForms
  } = state;
  return tabForms?.isPrinting ?? false;
};
exports.getIsPrinting = getIsPrinting;