"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNI_LANG = exports.OXLO_PORTAL_ID = exports.ONCHANGE_DEBOUNCE = exports.MOUSE_DEBOUNCE = exports.MATCHES_INVENTORY = exports.KEY_ENTER = exports.FR_LANG = exports.EN_LANG = void 0;
const ONCHANGE_DEBOUNCE = exports.ONCHANGE_DEBOUNCE = 300;
const MOUSE_DEBOUNCE = exports.MOUSE_DEBOUNCE = 200;
const KEY_ENTER = exports.KEY_ENTER = 13;
const MATCHES_INVENTORY = exports.MATCHES_INVENTORY = 'new';
const EN_LANG = exports.EN_LANG = 'en';
const FR_LANG = exports.FR_LANG = 'fr';
const UNI_LANG = exports.UNI_LANG = 'uni';
const OXLO_PORTAL_ID = exports.OXLO_PORTAL_ID = '994f995a-781e-40ec-984a-8f615bc79121';