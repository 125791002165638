"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shouldLockVehicle = exports.isVehicleDetailsValid = exports.isNew = exports.isNeverCreated = exports.isAllowedToEvaluateVehicle = exports.getLastScannedVin = exports.expirationDate = exports.checkIsUpdatingTradeIn = exports.checkIsLoadingTradeIn = exports.checkIsDeletingTradeIn = exports.checkIfLoading = void 0;
var _TradeInVehicleValidation = require("../tools/helpers/TradeInVehicleValidation");
const isVehicleDetailsValid = state => {
  const {
    paymentType,
    payment,
    balance,
    lienHolderName,
    expirationDate,
    ...validateValues
  } = state.tradeIn || {};
  const errors = (0, _TradeInVehicleValidation.checkForErrors)(validateValues);
  return Object.keys(errors).length === 0;
};
exports.isVehicleDetailsValid = isVehicleDetailsValid;
const isNeverCreated = state => {
  const {
    tradeIn
  } = state;
  if (!tradeIn) return false;
  const {
    id
  } = tradeIn || {};
  return typeof id === 'undefined' || !id;
};
exports.isNeverCreated = isNeverCreated;
const isAllowedToEvaluateVehicle = state => state.access?.EvaluateVehicle;
exports.isAllowedToEvaluateVehicle = isAllowedToEvaluateVehicle;
const shouldLockVehicle = state => {
  // Being a vehicle appraiser always allow to edit and bypass the vehicle lock
  const canAppraiseVehicle = isAllowedToEvaluateVehicle(state);
  return !canAppraiseVehicle && state.isReadOnlyMode;
};
exports.shouldLockVehicle = shouldLockVehicle;
const isNew = state => {
  return isNeverCreated(state) ? true : !isVehicleDetailsValid(state);
};
exports.isNew = isNew;
const expirationDate = state => {
  const {
    tradeIn
  } = state;
  const {
    dueMonth,
    dueYear
  } = tradeIn || {};
  return dueMonth && dueYear ? `${dueMonth + 1}/${dueYear}` : '';
};
exports.expirationDate = expirationDate;
const checkIfLoading = function (state) {
  for (var _len = arguments.length, actionsToCheck = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    actionsToCheck[_key - 1] = arguments[_key];
  }
  return state.loader.actions.some(action => actionsToCheck.includes(action.name));
};
exports.checkIfLoading = checkIfLoading;
const checkIsUpdatingTradeIn = state => {
  return checkIfLoading(state, 'UPDATE_TRADE_IN');
};
exports.checkIsUpdatingTradeIn = checkIsUpdatingTradeIn;
const checkIsLoadingTradeIn = state => {
  return checkIfLoading(state, 'LOAD_TRADE_IN');
};
exports.checkIsLoadingTradeIn = checkIsLoadingTradeIn;
const checkIsDeletingTradeIn = state => {
  return checkIfLoading(state, 'DELETE_TRADE_IN');
};
exports.checkIsDeletingTradeIn = checkIsDeletingTradeIn;
const getLastScannedVin = state => {
  return state.lastScannedVin;
};
exports.getLastScannedVin = getLastScannedVin;