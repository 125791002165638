"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../actions");
var _requestCatalogVehicles = require("../selectors/requestCatalogVehicles");
const {
  getCatalogVehicles
} = _commonsApi.VehicleESService;
function* _default() {
  const queryParams = yield (0, _effects.select)(_requestCatalogVehicles.getQueryParams);
  const body = yield (0, _effects.select)(_requestCatalogVehicles.getCatalogBody);
  queryParams.showroomModule = 'desking';
  try {
    const result = yield (0, _effects.call)(getCatalogVehicles, queryParams, body);
    yield (0, _effects.put)(_actions.Creators.loadVehiclesSuccess(result));
    yield (0, _effects.put)(_actions.Creators.setPaymentOptionsMetadata(result.paymentOptionsMetadata));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getVehicles'));
  }
}