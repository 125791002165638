"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../../actions");
var _updateDealJacket = _interopRequireDefault(require("./updateDealJacket"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _default(_ref) {
  let {
    updatedForm,
    fromPrint,
    checkedLang
  } = _ref;
  return function* () {
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
    yield (0, _effects.put)(_actions.Creators.setDealJacketForm(updatedForm));
    const updatedDealJacket = yield (0, _effects.call)(_updateDealJacket.default);
    if (updatedDealJacket) {
      yield (0, _effects.put)(_actions.Creators.updateTabFormsSuccess(updatedDealJacket));
    }
    if (fromPrint) {
      yield (0, _effects.put)(_actions.Creators.formPrint(updatedForm.instanceId, Array.isArray(updatedForm.customFields) ? updatedForm.customFields.map(customFieldGroup => customFieldGroup.fields).flat() : [], checkedLang));
    }
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
  }();
}