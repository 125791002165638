"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _actions = require("../../actions");
var _getDealJacket = _interopRequireDefault(require("./getDealJacket"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const dealJacket = yield (0, _effects.call)(_getDealJacket.default);
  if (dealJacket) {
    yield (0, _effects.put)(_actions.Creators.reloadTabFormsSuccess(dealJacket));
  }
}