"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehicleFilters = void 0;
var _commonsApi = require("@sm360/commons-api");
var _filters = require("../../constants/filters");
var _selectors = require("../selectors");
var _filters2 = require("./filters");
const {
  KEYS: VEHICLE_KEYS
} = _commonsApi.VehicleESService;

/**
 * @param {Object} state
 * @returns {Object}
 */
const getVehicleFilters = state => {
  const isNew = (0, _filters2.getIsCategoryNew)(state);
  const {
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  const dealerVehiclesOnly = (0, _filters2.getIsDealerVehiclesOnly)(state);
  const primaryOrganizationUnitIds = dealerVehiclesOnly ? [organizationUnitId] : [];
  const filters = {
    newVehicle: isNew,
    onlineVehiclesOnly: (0, _filters2.getIsOnlineVehiclesOnly)(state),
    primaryOrganizationUnitIds,
    dealerVehiclesOnly: (0, _filters2.getIsDealerVehiclesOnly)(state),
    vehicleInventoryStatuses: [_commonsApi.StatusService.VEHICLE_STATUS.FOR_SALE],
    [VEHICLE_KEYS.TEXT]: (0, _filters2.getSearchText)(state),
    // Options
    [VEHICLE_KEYS.BODY]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.BODY),
    [VEHICLE_KEYS.DRIVE]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.DRIVE),
    [VEHICLE_KEYS.ENGINE]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.ENGINE),
    [VEHICLE_KEYS.EXTERNAL_COLOR]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.EXTERNAL_COLOR),
    [VEHICLE_KEYS.FUEL]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.FUEL),
    [VEHICLE_KEYS.INTERNAL_COLOR]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.INTERNAL_COLOR),
    [VEHICLE_KEYS.MAKE]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.MAKE),
    [VEHICLE_KEYS.MODEL]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.MODEL),
    [VEHICLE_KEYS.TRIM]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.TRIM),
    [VEHICLE_KEYS.TRANSMISSION]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.TRANSMISSION),
    [VEHICLE_KEYS.YEAR]: (0, _filters2.getSelectedItem)(state, _filters.KEYS.YEAR),
    [VEHICLE_KEYS.MIN_SALE_PRICE]: (0, _filters2.getPriceRange)(state).selectedMinPrice,
    [VEHICLE_KEYS.MAX_SALE_PRICE]: (0, _filters2.getPriceRange)(state).selectedMaxPrice,
    [VEHICLE_KEYS.MIN_ODOMETER]: (0, _filters2.getOdometerRange)(state).selectedMinOdometer,
    [VEHICLE_KEYS.MAX_ODOMETER]: (0, _filters2.getOdometerRange)(state).selectedMaxOdometer
  };
  if (isNew) {
    filters.onlyBuiltVehicles = true;
  }
  return filters;
};
exports.getVehicleFilters = getVehicleFilters;