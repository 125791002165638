"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validVin = exports.validPostalCode = exports.validPhoneNumber = exports.validJson = exports.validIpAdress = exports.validHostname = exports.validFunction = exports.validEmailOrDomain = exports.validEmail = exports.validDate = exports.optional = void 0;
var _constants = require("./constants");
const validPhoneNumber = phoneValue => {
  if (phoneValue) {
    let phoneString = phoneValue;
    if (typeof phoneValue !== 'string') phoneString = String(phoneValue);
    return _constants.PHONE_REGEX.test(phoneString);
  }
  return false;
};
exports.validPhoneNumber = validPhoneNumber;
const validPostalCode = postalCode => {
  if (postalCode) {
    return _constants.CA_US_POSTAL_CODE_REGEX.test(postalCode);
  }
  return false;
};
exports.validPostalCode = validPostalCode;
const validEmail = email => {
  if (email) {
    return _constants.EMAIL_REGEX.test(email.toLowerCase());
  }
  return false;
};
exports.validEmail = validEmail;
const validEmailOrDomain = email => {
  if (email) {
    return _constants.EMAIL_OR_DOMAIN_REGEX.test(email);
  }
  return false;
};
exports.validEmailOrDomain = validEmailOrDomain;
const validDate = date => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj instanceof Date && !isNaN(dateObj);
  }
  return false;
};
exports.validDate = validDate;
const validVin = vin => {
  if (vin?.length) {
    if (vin.length !== 17) return false;
    const vinChars = vin.toUpperCase().split('');
    const transliterations = {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
      E: 5,
      F: 6,
      G: 7,
      H: 8,
      J: 1,
      K: 2,
      L: 3,
      M: 4,
      N: 5,
      P: 7,
      R: 9,
      S: 2,
      T: 3,
      U: 4,
      V: 5,
      W: 6,
      X: 7,
      Y: 8,
      Z: 9,
      0: 0,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9
    };
    const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
    let sum = 0;
    for (let i = 0; i < vinChars.length; i++) {
      const char = vinChars[i];
      const value = transliterations[char];
      if (value === undefined) {
        return false; // Invalid character in VIN
      }
      sum += value * weights[i];
    }
    const remainder = sum % 11;
    const checkDigit = remainder === 10 ? 'X' : remainder.toString();
    return checkDigit === vinChars[8];
  }
  return false;
};
exports.validVin = validVin;
const validJson = str => {
  if (typeof str !== 'string') return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
exports.validJson = validJson;
const validIpAdress = ip => {
  if (ip) {
    return _constants.IP_REGEX.test(ip);
  }
  return false;
};
exports.validIpAdress = validIpAdress;
const validHostname = hostname => {
  if (hostname) {
    return _constants.HOSTNAME_REGEX.test(hostname);
  }
  return false;
};
exports.validHostname = validHostname;
const validFunction = fn => {
  return typeof fn === 'function';
};

// Allow a validation function to be optional (accept falsy values)
exports.validFunction = validFunction;
const optional = validator => {
  return function (value) {
    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }
    return !value || validator(value, ...rest);
  };
};
exports.optional = optional;